import React, { useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  MenuItem,
  Menu,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  SwipeableDrawer,
  ListItemIcon,
} from "@mui/material";
import {
  Menu as MenuIcon,
  AccountCircle,
  ExitToApp,
} from "@mui/icons-material";
import { logout } from "../../actions";
import getColor from "../../components/getColor";
import stringValues from "../../components/stringValues";
import baseurl from "../../api/baseurl";
const drawerWidth = 260;
const useStyles = makeStyles((theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
    },

    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },

    title: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "block",
      },
    },

    drawerDesktop: {
      display: "none",
      flexShrink: 0,
      [theme.breakpoints.up("md")]: {
        display: "block",
        width: drawerWidth,
      },
    },

    drawerPaper: {
      width: drawerWidth,
    },

    drawerPaperDesktop: {
      width: drawerWidth,
      zIndex: 1,
      backgroundColor: "transparent",
      border: 0,
    },

    content: {
      flexGrow: 1,
      paddingTop: 60,
      [theme.breakpoints.up("md")]: {
        marginLeft: drawerWidth,
      },
    },
  })
);

const Wrapper = (props) => {
  const { children, logout, profile, auth } = props;
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const location = useLocation().pathname;

  const sideLinks = [
    {
      path: "/",
      name: "Dashboard",
      show: profile.type === 1 ? true : false,
    },
    {
      path: "/bookings",
      name: "Bookings",
      show: profile.type === 1 ? true : false,
    },
    {
      path: "/validation",
      name: "Validation",
      show: true,
    },
    {
      path: "/events",
      name: "Event Management",
      show: profile.type === 1 ? true : false,
    },
    {
      path: "/tickets",
      name: "Ticket Management",
      show: profile.type === 1 ? true : false,
    },

    {
      path: "/users",
      name: "User Management",
      show: profile.type === 1 ? true : false,
    },
    {
      path: "/admin",
      name: "Admin Management",
      show: profile.type === 1 ? true : false,
    },
    {
      path: "/promo",
      name: "Promo Codes",
      show: profile.type === 1 ? true : false,
    },
  ];

  const renderSideLinks = () => {
    return sideLinks
      .filter((item) => item.show)
      .map((item, index) => (
        <ListItem
          key={item.path}
          style={{
            backgroundColor:
              item.path === "/"
                ? location === "/" && "#fff"
                : location.includes(item.path) && "#fff",
            borderTopLeftRadius: 18,
            borderBottomLeftRadius: 18,
          }}
          button
          onClick={() => {
            setOpenDrawer(false);
          }}
          component={Link}
          to={item.path}
        >
          <ListItemText
            primary={
              <Typography
                color="primary"
                style={{
                  fontWeight: 500,
                  letterSpacing: 0.5,
                  color:
                    item.path === "/"
                      ? location === "/"
                        ? "#222"
                        : "#fff"
                      : location.includes(item.path)
                      ? "#222"
                      : "#fff",
                }}
              >
                {item.name}
              </Typography>
            }
          />
        </ListItem>
      ));
  };

  const renderMenu = () => {
    return (
      <Menu
        id="account-menu"
        style={{}}
        anchorEl={openMenu}
        open={Boolean(openMenu)}
        onClose={() => setOpenMenu(false)}
      >
        <MenuItem
          style={{ width: 170, fontWeight: 500 }}
          component={Link}
          to="/account"
          onClick={() => setOpenMenu(false)}
        >
          <AccountCircle style={{ marginRight: 10 }} />
          My Account
        </MenuItem>
        <MenuItem
          style={{ width: 170, fontWeight: 500 }}
          onClick={() => {
            setOpenMenu(false);
            logout();
          }}
        >
          <ExitToApp style={{ marginRight: 10 }} />
          Logout
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        style={{
          zIndex: 2,
          boxShadow: "0px 1px 2px #ddd",
          backgroundColor: "#fff",
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="open drawer"
            onClick={(e) => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>

          <Link to="/" style={{ textDecoration: "none" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt={stringValues.name}
                src="/logo512.png"
                style={{
                  objectFit: "contain",
                  height: 30,
                  width: 30,

                  borderRadius: "50%",
                }}
              />{" "}
              <Typography
                className={classes.title}
                style={{
                  color: "#000",
                  fontWeight: 600,
                  fontSize: 16,
                  marginLeft: 10,
                }}
              >
                {stringValues.name}
              </Typography>
            </div>
          </Link>
          <div className={classes.grow} />
          <Typography style={{ color: "black", fontWeight: 500 }}>
            USER:{" "}
            <b style={{ color: getColor.secondary }}>{profile.username}</b>
          </Typography>
          <IconButton
            edge="end"
            onClick={(e) => setOpenMenu(e.currentTarget)}
            color="secondary"
            style={{ marginRight: 0, marginLeft: 3 }}
          >
            <AccountCircle fontSize="large" />
          </IconButton>
          {renderMenu()}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        anchor="left"
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        onOpen={() => setOpenDrawer(true)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} />
        <List
          style={{
            background: getColor.primary,
            height: "100%",
          }}
        >
          {renderSideLinks()}
        </List>
      </SwipeableDrawer>
      <Drawer
        className={classes.drawerDesktop}
        anchor="left"
        variant="persistent"
        open={true}
        classes={{
          paper: classes.drawerPaperDesktop,
        }}
      >
        <div style={{ height: 54 }} />

        <List
          style={{
            background: getColor.primary,
            height: "100%",
            overflowY: "auto",
          }}
        >
          {renderSideLinks()}
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};
export default connect(mapStateToProps, { logout })(Wrapper);
