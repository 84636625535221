import { Container, Typography, Link as ALink, Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import stringValues from "../../components/stringValues";
const Footer = () => {
  const QUICK_LINKS = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About Us",
      link: "/about",
    },
    {
      name: "Contact Us",
      link: "/customer-care",
    },
    {
      name: "FAQ",
      link: "/faq",
    },
  ];
  const LEGALS = [
    {
      name: "Terms & Conditions",
      link: "/terms-and-conditions",
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      name: "Refund Policy",
      link: "/refund-policy",
    },
  ];
  const SOCIAL_MEDIA = [
    {
      name: "Facebook",
      image: "/facebook.png",
      link: "https://www.facebook.com/hereusmizo",
    },
    {
      name: "Instagram",
      image: "/instagram.png",
      link: "https://www.instagram.com/hereus.in",
    },
  ];
  const renderQuickLinks = () => (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
      {QUICK_LINKS.map((item) => (
        <Typography
          key={item.name}
          component={Link}
          to={item.link}
          style={{ color: "#fff", marginBottom: 5, textDecoration: "none" }}
        >
          {item.name}
        </Typography>
      ))}
    </div>
  );
  const renderLegals = () => (
    <div style={{ display: "flex", flexDirection: "column", marginTop: 10 }}>
      {LEGALS.map((item) => (
        <Typography
          key={item.name}
          component={Link}
          to={item.link}
          style={{ color: "#fff", marginBottom: 5, textDecoration: "none" }}
        >
          {item.name}
        </Typography>
      ))}
    </div>
  );

  return (
    <div
      style={{
        background: "#434343",
        paddingTop: 30,
        paddingBottom: 50,
        marginTop: 60,
      }}
    >
      <Container style={{ paddingTop: 30, color: "#fff" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                alt="BookMaster"
                src="/logo512.png"
                style={{ height: 110, width: 110 }}
              />
              <Typography style={{ fontWeight: 600, fontSize: 16 }}>
                BookMaster
              </Typography>
            </div>
          </Grid>
          <Typography></Typography>
          <Grid item xs={6} sm={4}>
            <Typography style={{ fontWeight: "bolder" }}>
              QUICK LINKS
            </Typography>
            {renderQuickLinks()}
            <Typography
              component="a"
              href="https://hereus.in"
              target="_blank"
              style={{ color: "#fff", marginBottom: 5, textDecoration: "none" }}
            >
              Developers
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography style={{ fontWeight: "bolder" }}>LEGAL</Typography>
            {renderLegals()}
          </Grid>
        </Grid>
      </Container>
      <Typography
        style={{
          color: "#fff",
          fontSize: 14,
          fontWeight: 400,
          textAlign: "center",
          marginTop: 30,
        }}
      >
        Copyright © {stringValues.name} {new Date().getFullYear()} | All rights
        reserved.
      </Typography>
    </div>
  );
};

export default Footer;
