import { createTheme } from "@mui/material";
import "@fontsource/poppins";
const theme = createTheme({
  palette: {
    primary: {
      main: "#FF5400",
    },
    secondary: {
      main: "#4285F4",
    },
    info: {
      main: "#fff",
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: "Poppins",
  },
  shape: {
    borderRadius: 20,
  },
});
export default theme;

//black #535150
//primary dal #CA7DF9
//blue ang chi #6161F3
