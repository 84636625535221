import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";

const AlertMessage = ({ message, status, toast, setToast }) => {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={toast}
      autoHideDuration={3000}
      onClose={() => {
        setToast(false);
      }}
    >
      <SnackbarContent
        style={{ backgroundColor: status }}
        message={message}
        onClose={() => {
          setToast(false);
        }}
        action={
          <IconButton
            key="close"
            color="inherit"
            onClick={() => setToast(false)}
          >
            <Close />
          </IconButton>
        }
      ></SnackbarContent>
    </Snackbar>
  );
};

export default AlertMessage;
