import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import Footer from "./Footer";
import {
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Drawer,
  Container,
  Tooltip,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import getColor from "../../components/getColor";
import { connect } from "react-redux";
import SignUp from "../../pages/Public/SignUp";
import Login from "../../pages/Public/Login";

const drawerWidth = 200;
const useStyles = makeStyles((theme) =>
  createStyles({
    menuButton: {
      marginRight: 0,
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    hideOnMobile: {
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },

    drawerPaper: {
      width: drawerWidth,
    },
  })
);

const Wrapper = ({ children, auth }) => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openLoginDialog, setOpenLoginDialog] = useState(false);
  const [openSignupDialog, setOpenSignupDialog] = useState(false);
  const location = useLocation().pathname;

  const MENU_ITEM = [
    {
      path: "/",
      name: "Home",
      show: true,
    },
    {
      path: "/about",
      name: "About Us",
      show: true,
    },
    {
      path: "/customer-care",
      name: "Customer Care",
      show: true,
    },
    {
      path: "/my-bookings",
      name: "My Bookings",
      show: true,
    },
    {
      path: "/signup",
      name: "Sign Up/Login",
      show: auth === 1 ? false : true,
    },
  ];

  const renderLoginDialog = () => {
    if (openLoginDialog) {
      return (
        <Login
          open={openLoginDialog}
          setOpen={setOpenLoginDialog}
          setOpenOtherDialog={setOpenSignupDialog}
        />
      );
    }
  };
  const renderSignupDialog = () => {
    if (openSignupDialog) {
      return (
        <SignUp
          open={openSignupDialog}
          setOpen={setOpenSignupDialog}
          setOpenOtherDialog={setOpenLoginDialog}
        />
      );
    }
  };

  const renderMenuItem = () => {
    return MENU_ITEM.filter((item) => item.show).map((item) => {
      const path = item.path === "/signup" ? null : item.path;
      return (
        <ListItem
          key={item.path}
          style={{
            backgroundColor:
              item.path === "/"
                ? location === "/" && "#fff"
                : location.includes(item.path) && "#fff",
            borderTopLeftRadius: 18,
            borderBottomLeftRadius: 18,
          }}
          button
          component={Link}
          onClick={() => {
            setOpenDrawer(false);
            if (item.path === "/signup") setOpenSignupDialog(true);
          }}
          to={path}
        >
          <ListItemText
            primary={
              <Typography
                color="primary"
                style={{
                  fontWeight: 600,
                  color:
                    item.path === "/"
                      ? location === "/"
                        ? "#222"
                        : "#fff"
                      : location.includes(item.path)
                      ? "#222"
                      : "#fff",
                }}
              >
                {item.name}
              </Typography>
            }
          />
        </ListItem>
      );
    });
  };

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        style={{
          paddingTop: 8,
          paddingBottom: 8,
          zIndex: 2,
          backgroundColor: "#fff",
          boxShadow: "1px 2px 3px #eee",
        }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="primary"
            aria-label="open drawer"
            onClick={(e) => {
              setOpenDrawer(true);
            }}
          >
            <MenuIcon />
          </IconButton>
          <Container>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link to="/" style={{ textDecoration: "none" }}>
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt={"BookMaster"}
                    src="/logo512.png"
                    style={{
                      objectFit: "contain",
                      height: 40,
                      width: 40,
                    }}
                  />{" "}
                  <Typography
                    style={{
                      color: "#000",
                      fontWeight: 600,
                      fontSize: 16,
                      marginLeft: 4,
                    }}
                  >
                    BookMaster
                  </Typography>
                </div>
              </Link>
              <div
                style={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {MENU_ITEM.filter((item) => item.show).map((item) => {
                  const path = item.path === "/signup" ? null : item.path;
                  return (
                    <Link
                      className={classes.hideOnMobile}
                      key={item.path}
                      title={item.name}
                      onClick={() => {
                        if (item.path === "/signup") setOpenSignupDialog(true);
                      }}
                      to={path}
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        textDecoration: "none",
                        marginLeft: 12,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 10,
                        paddingRight: 10,
                        borderRadius: 20,
                        color:
                          item.path === "/"
                            ? location === "/"
                              ? "#fff"
                              : "#555"
                            : location.includes(item.path)
                            ? "#fff"
                            : "#555",
                        background:
                          item.path === "/"
                            ? location === "/"
                              ? getColor.primary
                              : "#fff"
                            : location.includes(item.path)
                            ? getColor.primary
                            : "#fff",
                        // textTransform: "uppercase",
                      }}
                    >
                      {item.name}
                    </Link>
                  );
                })}
                {auth === 1 ? (
                  <Tooltip title="Account Settings">
                    <IconButton
                      style={{ marginLeft: 12 }}
                      component={Link}
                      to="/account-settings"
                      color={
                        location === "/account-settings" ? "primary" : "default"
                      }
                    >
                      <AccountCircle />
                    </IconButton>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} />
        <List
          dense
          style={{
            background: getColor.primary,
            flexGrow: 1,
            paddingTop: 20,
            height: "100%",
          }}
        >
          {renderMenuItem()}
        </List>
      </Drawer>
      <Container style={{ flexGrow: 1, paddingTop: 80, minHeight: 800 }}>
        {children}
      </Container>
      <Footer />
      {renderLoginDialog()}
      {renderSignupDialog()}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(Wrapper);
