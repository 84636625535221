import React, { lazy, Suspense, useEffect } from "react";
import { connect } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import {
  checkAuth,
  fetchDecoded,
  fetchProfile,
  fetchDefaults,
} from "./actions";
import PublicWrapper from "./Wrapper/Public/Wrapper";
import AdminWrapper from "./Wrapper/Admin/Wrapper";

const Home = lazy(() => import("./pages/Public/Home"));
const AboutUs = lazy(() => import("./pages/Public/AboutUs"));
const EventBooking = lazy(() =>
  import("./pages/Public/EventBooking/EventBooking")
);
const TicketBooking = lazy(() =>
  import("./pages/Public/EventBooking/TicketBooking")
);
const MyBookings = lazy(() => import("./pages/Public/EventBooking/MyBookings"));
const MyTicket = lazy(() => import("./pages/Public/EventBooking/MyTicket"));
const MyAccount = lazy(() => import("./pages/Public/MyAccount"));
const CustomerCare = lazy(() => import("./pages/Public/CustomerCare"));
const TermsAndConditions = lazy(() =>
  import("./pages/Public/TermsAndConditions")
);
const FAQ = lazy(() => import("./pages/Public/FAQ"));
const RefundPolicy = lazy(() => import("./pages/Public/RefundPolicy"));
const PrivacyPolicy = lazy(() => import("./pages/Public/PrivacyPolicy"));
const AccountSettings = lazy(() => import("./pages/Public/AccountSettings"));
const Login = lazy(() => import("./pages/Admin/Login"));
const UserManagement = lazy(() =>
  import("./pages/Admin/UserManagement/UserManagement")
);
const Administrator = lazy(() =>
  import("./pages/Admin/AdminManagment/Administrator")
);
const Dashboard = lazy(() => import("./pages/Admin/Dashboard/Dashboard"));
const EventManagement = lazy(() =>
  import("./pages/Admin/EventManagement/EventManagement")
);
const TicketManagement = lazy(() =>
  import("./pages/Admin/TicketManagement/TicketManagement")
);
const Validation = lazy(() => import("./pages/Admin/Validation/Validation"));
const Bookings = lazy(() => import("./pages/Admin/Bookings/Bookings"));
const Statistics = lazy(() => import("./pages/Admin/Bookings/Statistics"));
const OfflineReservation = lazy(() =>
  import("./pages/Admin/Bookings/OfflineReservation")
);
const AllBookings = lazy(() => import("./pages/Admin/Bookings/AllBookings"));
const SoldValidation = lazy(() =>
  import("./pages/Admin/Validation/SoldValidation")
);
const SoldValidationManual = lazy(() =>
  import("./pages/Admin/Bookings/SoldValidationManual")
);
const EntryValidation = lazy(() =>
  import("./pages/Admin/Validation/EntryValidation")
);
const EntryValidationManual = lazy(() =>
  import("./pages/Admin/Bookings/EntryValidationManual")
);
const PromoCode = lazy(() => import("./pages/Admin/PromoCode/PromoCode"));
const NotFound = lazy(() => import("./NotFound"));

const App = (props) => {
  const {
    checkAuth,
    fetchDecoded,
    fetchProfile,
    auth,
    fetchDefaults,
    profile,
  } = props;

  useEffect(() => {
    checkAuth();
    if (auth) fetchData();
    else fetchDefaults();
  }, [auth]);

  const fetchData = async () => {
    await Promise.all([fetchDefaults(), fetchDecoded(), fetchProfile()]);
  };

  if (auth === 2) {
    //admin
    const ROUTES = [
      {
        show: profile.type === 1 ? true : false,
        path: "/",
        component: <Dashboard />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/users",
        component: <UserManagement />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/admin",
        component: <Administrator />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/events",
        component: <EventManagement />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/tickets",
        component: <TicketManagement />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/tickets/:event",
        component: <TicketManagement />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings",
        component: <Bookings />,
      },
      {
        show: true,
        path: profile.type === 1 ? "/validation" : "",
        component: <Validation />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/offline-reserve",
        component: <OfflineReservation />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/offline-reserve/:event",
        component: <OfflineReservation />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/offline-reserve/:event/:ticket/:printed/:sold/:status",
        component: <OfflineReservation />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/all",
        component: <AllBookings />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/all/:event",
        component: <AllBookings />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/all/:event/:ticket/:mode/:sold/:status/:validated/:print_request",
        component: <AllBookings />,
      },
      {
        show: true,
        path: "/validation/sold-validation",
        component: <SoldValidation />,
      },
      {
        show: true,
        path: "/bookings/sold-validation-manual",
        component: <SoldValidationManual />,
      },
      {
        show: true,
        path: "/validation/entry-validation",
        component: <EntryValidation />,
      },
      {
        show: true,
        path: "/bookings/entry-validation-manual",
        component: <EntryValidationManual />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/statistics",
        component: <Statistics />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/bookings/statistics/:event",
        component: <Statistics />,
      },
      {
        show: profile.type === 1 ? true : false,
        path: "/promo",
        component: <PromoCode />,
      },
    ];
    return (
      <AdminWrapper>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {ROUTES.filter((i) => i.show).map((item) => (
              <Route
                key={item.path}
                path={item.path}
                exact
                element={item.component}
              />
            ))}
          </Routes>
        </Suspense>
      </AdminWrapper>
    );
  } else if (auth === 1) {
    //public
    const ROUTES = [
      { path: "/", component: <Home /> },
      { path: "/about", component: <AboutUs /> },
      { path: "/events", component: <EventBooking /> },
      { path: "/events/:id", component: <EventBooking /> },
      { path: "/ticket-booking/:event/:ticket", component: <TicketBooking /> },
      { path: "/my-bookings", component: <MyBookings /> },
      { path: "/my-bookings/:id", component: <MyTicket /> },
      { path: "/customer-care", component: <CustomerCare /> },
      { path: "/terms-and-conditions", component: <TermsAndConditions /> },
      { path: "/privacy-policy", component: <PrivacyPolicy /> },
      { path: "/refund-policy", component: <RefundPolicy /> },
      { path: "/faq", component: <FAQ /> },
      {
        path: "/account-settings",
        component: <AccountSettings />,
      },
      {
        path: "/account-settings/profile",
        component: <MyAccount />,
      },
    ];
    return (
      <PublicWrapper>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {ROUTES.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                exact
                element={item.component}
              />
            ))}
          </Routes>
        </Suspense>
      </PublicWrapper>
    );
  } else if (auth === 0) {
    //public
    const ROUTES = [
      { path: "/", component: <Home /> },
      { path: "/iiihh", component: <Login /> },
      { path: "/about", component: <AboutUs /> },
      { path: "/events", component: <EventBooking /> },
      { path: "/events/:id", component: <EventBooking /> },
      { path: "/customer-care", component: <CustomerCare /> },
      { path: "/my-bookings", component: <MyBookings /> },
      { path: "/ticket-booking/:event/:ticket", component: <TicketBooking /> },
      { path: "/terms-and-conditions", component: <TermsAndConditions /> },
      { path: "/privacy-policy", component: <PrivacyPolicy /> },
      { path: "/refund-policy", component: <RefundPolicy /> },
      { path: "/faq", component: <FAQ /> },
    ];
    return (
      <PublicWrapper>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: 300,
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <Routes>
            <Route path="*" element={<NotFound />} />
            {ROUTES.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                exact
                element={item.component}
              />
            ))}
          </Routes>
        </Suspense>
      </PublicWrapper>
    );
  } else {
    return (
      <div
        style={{
          marginTop: 300,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return { auth: state.auth, profile: state.profile };
};
export default connect(mapStateToProps, {
  checkAuth,
  fetchDecoded,
  fetchProfile,
  fetchDefaults,
})(App);
