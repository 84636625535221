import api from "../api/api";
import { decodeToken } from "react-jwt";

export const checkAuth = () => async (dispatch) => {
  try {
    if (localStorage.getItem("admin-auth")) {
      return dispatch({
        type: "AUTH_ADMIN",
      });
    } else if (localStorage.getItem("user-auth")) {
      console.log(localStorage.getItem("user-auth"));
      return dispatch({
        type: "AUTH_USER",
      });
    } else {
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  } catch (error) {
    console.log(error);
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
};

export const fetchDecoded = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("user-auth")) {
    token = localStorage.getItem("user-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  const decoded = await decodeToken(token);
  return dispatch({
    type: "FETCH_DECODED",
    payload: decoded,
  });
};

export const fetchProfile = () => async (dispatch) => {
  let token;
  if (localStorage.getItem("user-auth")) {
    token = localStorage.getItem("user-auth");
  } else if (localStorage.getItem("admin-auth")) {
    token = localStorage.getItem("admin-auth");
  } else {
    localStorage.clear();
    return dispatch({
      type: "AUTH_FAIL",
    });
  }
  const decoded = await decodeToken(token);
  const url =
    decoded.type === 1
      ? `/api/users/${decoded.id}`
      : `/api/admin/${decoded.id}`;
  try {
    const response = await api.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return dispatch({
      type: "FETCH_PROFILE",
      payload: response.data.data,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  }
};
export const fetchDefaults = () => async (dispatch) => {
  try {
    const response = await api.get(`/api/default`);
    dispatch({
      type: "FETCH_DEFAULTS",
      payload: response.data.defaults,
    });
    dispatch({
      type: "FETCH_EVENTS",
      payload: response.data.events,
    });
    dispatch({
      type: "FETCH_TICKET",
      payload: response.data.ticket,
    });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.clear();
      return dispatch({
        type: "AUTH_FAIL",
      });
    }
  }
};

export const logout = () => async (dispatch) => {
  window.location.href = "/";
  localStorage.clear();
  return dispatch({
    type: "AUTH_FAIL",
  });
};
