import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import React, { useState } from "react";
import { decodeToken } from "react-jwt";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { checkAuth } from "../../actions";
import api from "../../api/api";
import AlertMessage from "../../components/AlertMessage";
import getColor from "../../components/getColor";
const LOGIN_VALUES = {
  email: "",
  password: "",
};
const Login = ({ open, setOpen, setOpenOtherDialog, checkAuth }) => {
  const [values, setValues] = useState(LOGIN_VALUES);
  const [isWait, setIsWait] = useState(false);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);
  const [openReset, setOpenReset] = useState(false);
  const [otp, setOtp] = useState("");
  const [id, setId] = useState("");
  const [password, setPassword] = useState({ password: "", confirm: "" });
  const [done, setDone] = useState(false);
  const [token, setToken] = useState(null);

  const onLogin = async (e) => {
    e.preventDefault();
    try {
      setIsWait(true);
      const response = await api.post(`/api/users/login`, values);
      const token = response.data.token;
      const decoded = await decodeToken(token);
      if (decoded.type === 1) {
        localStorage.setItem("user-auth", token);
        setOpen(false);
        setValues(LOGIN_VALUES);
        checkAuth();
        setStatus(green[700]);
        setMessage("Authentication Success");
        setToast(true);
      } else {
        setStatus(red[700]);
        setMessage("Sorry, We cannot verify your authentication token.");
        setToast(true);
      }
      setIsWait(false);
    } catch (error) {
      setStatus(red[700]);
      setIsWait(false);
      if (error.response.data) {
        setMessage(error.response.data.message);
      } else {
        setMessage("Something went wrong. Check your internet connection.");
      }
      setToast(true);
    }
  };

  const requestOTP = async (e) => {
    e.preventDefault();
    try {
      setIsWait(true);
      const response = await api.get(`/api/users/request-otp/${values.email}`);
      setToken(response.data.token);
      setIsWait(false);
      setStatus(green[700]);
      setMessage("OTP is sent to your registered email.");
      return setToast(true);
    } catch (error) {
      setIsWait(false);
      setStatus(red[700]);
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage("Something went wrong. Check your internet connection.");
      }
      return setToast(true);
    }
  };

  const checkOtp = async (e) => {
    e.preventDefault();
    try {
      setIsWait(true);
      const response = await api.post(
        `/api/users/check-otp`,
        { otp: otp },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      setId(response.data.id);
      setIsWait(false);
      setStatus(green[700]);
      setMessage(response.data.message);
      return setToast(true);
    } catch (error) {
      setIsWait(false);
      setStatus(red[700]);
      if (error.response) {
        setMessage(error.response.data.message);
      } else {
        setMessage("Something went wrong. Check your internet connection.");
      }
      return setToast(true);
    }
  };

  const submitPassword = async (e) => {
    e.preventDefault();
    if (password.password === password.confirm) {
      try {
        setIsWait(true);
        await api.patch(`/api/users/change-password/${id}`, password, {
          headers: {
            Authorization: "Bearer " + token,
          },
        });
        setIsWait(false);
        setPassword({
          password: "",
          confirm: "",
        });

        setToken(null);
        setOtp("");
        setId("");
        setDone(true);
      } catch (error) {
        setIsWait(false);
        setStatus(red[700]);
        setMessage("Something went wrong. Please try again");
        return setToast(true);
      }
    } else {
      setIsWait(false);
      setStatus(red[700]);
      setMessage("Password should be same. Please try again.");
      return setToast(true);
    }
  };

  const renderSuccess = () => {
    return (
      <div>
        <Typography
          style={{ fontSize: 16, marginTop: 30, fontWeight: 500 }}
          align="center"
        >
          Congratulations! You have updated your login password successfully.
          You can go back to login page and use your new password to login.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 30,
          }}
        >
          <Button
            onClick={() => {
              setOpenReset(false);
              setDone(false);
            }}
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
          >
            Done
          </Button>
        </div>
      </div>
    );
  };

  const renderRequestOTP = () => (
    <form onSubmit={requestOTP}>
      <Typography style={{ fontSize: 14, fontWeight: 600 }}>
        Enter Registered Email Address
      </Typography>
      <TextField
        autoFocus
        required
        type="email"
        color="secondary"
        size="small"
        fullWidth
        variant="outlined"
        margin="dense"
        placeholder="Example: yourname@mail.com"
        value={values.email}
        onChange={(e) => {
          setValues({ ...values, email: e.target.value });
        }}
      />

      <Button
        startIcon={
          isWait ? <CircularProgress size={15} color="primary" /> : null
        }
        disabled={isWait}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        style={{
          marginTop: 20,
          fontWeight: 600,
          textTransform: "none",
          letterSpacing: 1,
        }}
      >
        Get OTP
      </Button>
    </form>
  );
  const renderOTPForm = () => (
    <form onSubmit={checkOtp}>
      <Typography
        style={{ fontSize: 14, marginTop: 30, fontWeight: 500, color: "green" }}
        align="center"
      >
        We have sent an OTP to your email: <b>{values.email}</b>
      </Typography>
      <Typography style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}>
        Enter OTP
      </Typography>
      <TextField
        autoFocus
        required
        type="number"
        color="secondary"
        size="small"
        fullWidth
        variant="outlined"
        margin="dense"
        value={otp}
        onChange={(e) => {
          setOtp(e.target.value);
        }}
      />

      <Button
        startIcon={
          isWait ? <CircularProgress size={15} color="primary" /> : null
        }
        disabled={isWait}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        style={{
          marginTop: 20,
          fontWeight: 600,
          textTransform: "none",
          letterSpacing: 1,
        }}
      >
        Verify
      </Button>
    </form>
  );

  const renderPasswordForm = () => (
    <form onSubmit={submitPassword}>
      <Typography style={{ fontSize: 14, fontWeight: 600 }}>
        Enter New Password
      </Typography>
      <TextField
        required
        placeholder="New Password"
        type="password"
        color="secondary"
        size="small"
        fullWidth
        variant="outlined"
        margin="dense"
        value={password.password}
        onChange={(e) => {
          setPassword({ ...password, password: e.target.value });
        }}
      />
      <Typography style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}>
        Re-enter Password
      </Typography>
      <TextField
        required
        type="password"
        color="secondary"
        placeholder="New Password Again"
        size="small"
        fullWidth
        variant="outlined"
        margin="dense"
        value={password.confirm}
        onChange={(e) => {
          setPassword({ ...password, confirm: e.target.value });
        }}
      />

      <Button
        startIcon={
          isWait ? <CircularProgress size={15} color="primary" /> : null
        }
        disabled={isWait}
        type="submit"
        variant="contained"
        color="primary"
        fullWidth
        style={{
          marginTop: 20,
          fontWeight: 600,
          textTransform: "none",
          letterSpacing: 1,
        }}
      >
        Submit
      </Button>
    </form>
  );
  const renderReset = () => {
    if (openReset) {
      return (
        <Dialog
          open={openReset}
          scroll="body"
          maxWidth="xs"
          fullWidth
          onClose={() => {
            setOpenReset(false);
            setToken(null);
            setOtp("");
            setDone(false);
            setId("");
          }}
        >
          <div
            style={{
              display: "flex",
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 20,
              marginBottom: -30,
            }}
          ></div>
          <DialogContent style={{ paddingBottom: 30 }}>
            <Typography
              style={{ fontSize: 25, fontWeight: 800, marginTop: 20 }}
            >
              Forgot Your Password?
            </Typography>
            <Typography style={{ color: "#555", fontSize: 12 }}>
              Don't worry, You can reset your password.
            </Typography>
            <div
              style={{
                marginTop: 20,
              }}
            >
              {id
                ? renderPasswordForm()
                : token
                ? renderOTPForm()
                : done
                ? renderSuccess()
                : renderRequestOTP()}
            </div>
          </DialogContent>
        </Dialog>
      );
    }
  };

  return (
    <Dialog open={open} scroll="body" maxWidth="xs" fullWidth>
      <div
        style={{
          display: "flex",
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
          marginBottom: -30,
        }}
      ></div>
      <DialogContent style={{ paddingBottom: 30 }}>
        <Typography style={{ fontSize: 25, fontWeight: 800, marginTop: 20 }}>
          Login
        </Typography>
        <Typography style={{ color: "#555", fontSize: 12 }}>
          Welcome Back!
        </Typography>
        <div
          style={{
            marginTop: 20,
          }}
        >
          <form onSubmit={onLogin}>
            <Typography style={{ fontSize: 14, fontWeight: 600 }}>
              Enter Email Address
            </Typography>
            <TextField
              required
              type="email"
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Example: yourname@mail.com"
              value={values.email}
              onChange={(e) => {
                setValues({ ...values, email: e.target.value });
              }}
            />
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}
            >
              Enter Password
            </Typography>
            <TextField
              required
              type="password"
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Your login password"
              value={values.password}
              onChange={(e) => {
                setValues({
                  ...values,
                  password: e.target.value,
                });
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: 20,
              }}
            >
              <Link
                to={null}
                onClick={() => {
                  setOpenReset(true);
                }}
                style={{ textDecoration: "none", color: getColor.primary }}
              >
                Forget Password?
              </Link>
            </div>
            <Button
              startIcon={
                isWait ? <CircularProgress size={15} color="primary" /> : null
              }
              disabled={isWait}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{
                marginTop: 20,
                fontWeight: 600,
                textTransform: "none",
                letterSpacing: 1,
              }}
            >
              Login
            </Button>
          </form>
          <div
            style={{
              display: "flex",
              marginTop: 20,
            }}
          >
            Not registered yet?{" "}
            <Link
              onClick={() => {
                setOpen(false);
                setOpenOtherDialog(true);
                setValues(LOGIN_VALUES);
              }}
              // to="/reset-password"
              style={{
                textDecoration: "none",
                color: getColor.primary,
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              Sign Up
            </Link>
          </div>
        </div>
      </DialogContent>
      {renderReset()}
      <AlertMessage
        message={message}
        status={status}
        setToast={setToast}
        toast={toast}
      />
    </Dialog>
  );
};

export default connect(null, { checkAuth })(Login);
