import { Close } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import React, { useState } from "react";
import { decodeToken } from "react-jwt";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { checkAuth } from "../../actions";
import api from "../../api/api";
import AlertMessage from "../../components/AlertMessage";
import getColor from "../../components/getColor";
const SIGNUP_VALUES = {
  name: "",
  email: "",
  contact: "",
  password: "",
  confirm: "",
};
const SignUp = ({ open, setOpen, setOpenOtherDialog, checkAuth }) => {
  const [values, setValues] = useState(SIGNUP_VALUES);
  const [isWait, setIsWait] = useState(false);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(null);

  const onSignup = async (e) => {
    e.preventDefault();
    if (values.password === values.confirm) {
      try {
        setIsWait(true);
        const response = await api.post(`/api/users/signup`, values);
        const token = response.data.token;
        const decoded = await decodeToken(token);

        if (decoded.type === 1) {
          localStorage.setItem("user-auth", token);
          setOpen(false);
          setValues(SIGNUP_VALUES);
          checkAuth();
          setToast(true);
          setStatus(green[700]);
          setMessage("Account created successfully.");
          setToast(true);
        } else {
          setStatus(red[700]);
          setMessage("Sorry, We cannot verify your authentication token.");
          setToast(true);
        }
        setIsWait(false);
      } catch (error) {
        setStatus(red[700]);
        setIsWait(false);
        if (error.response.data) {
          setMessage(error.response.data.message);
        } else {
          setMessage("Something went wrong. Check your internet connection.");
        }
        setToast(true);
      }
    } else {
      setStatus(red[700]);
      setMessage("Password should be exactly same. Try again.");
      setToast(true);
    }
  };

  return (
    <Dialog open={open} scroll="body" maxWidth="xs" fullWidth>
      <div
        style={{
          display: "flex",
          paddingLeft: 20,
          paddingRight: 20,
          paddingTop: 20,
          marginBottom: -30,
        }}
      ></div>
      <DialogContent style={{ paddingBottom: 30 }}>
        <Typography style={{ fontSize: 25, fontWeight: 800, marginTop: 20 }}>
          Sign Up
        </Typography>
        <Typography style={{ color: "#555", fontSize: 12 }}>
          Please fill up all the fields
        </Typography>
        <div
          style={{
            marginTop: 20,
          }}
        >
          <form onSubmit={onSignup}>
            <Typography style={{ fontSize: 14, fontWeight: 600 }}>
              Enter Your Name
            </Typography>
            <TextField
              required
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Hming Pum"
              value={values.name}
              onChange={(e) => {
                setValues({ ...values, name: e.target.value });
              }}
            />
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}
            >
              Enter Email Address
            </Typography>
            <TextField
              required
              type="email"
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Example: yourname@mail.com"
              value={values.email}
              onChange={(e) => {
                setValues({ ...values, email: e.target.value });
              }}
            />
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}
            >
              Enter Phone Number
            </Typography>
            <TextField
              required
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="10 Digits Phone Number"
              value={values.contact}
              onChange={(e) => {
                setValues({
                  ...values,
                  contact: e.target.value,
                });
              }}
            />
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}
            >
              Enter New Password
            </Typography>
            <TextField
              required
              type="password"
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="New Password"
              value={values.password}
              onChange={(e) => {
                setValues({
                  ...values,
                  password: e.target.value,
                });
              }}
            />
            <Typography
              style={{ fontSize: 14, fontWeight: 600, marginTop: 20 }}
            >
              Re-enter Password
            </Typography>
            <TextField
              required
              type="password"
              color="secondary"
              size="small"
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Type password again"
              value={values.confirm}
              onChange={(e) => {
                setValues({
                  ...values,
                  confirm: e.target.value,
                });
              }}
            />

            <Button
              startIcon={
                isWait ? <CircularProgress size={15} color="primary" /> : null
              }
              disabled={isWait}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              style={{
                marginTop: 20,
                fontWeight: 600,
                textTransform: "none",
                letterSpacing: 1,
              }}
            >
              Sign Up
            </Button>
          </form>
          <div
            style={{
              display: "flex",
              marginTop: 20,
            }}
          >
            Already have account?{" "}
            <Link
              onClick={() => {
                setOpen(false);
                setOpenOtherDialog(true);
                setValues(SIGNUP_VALUES);
              }}
              // to="/reset-password"
              style={{
                textDecoration: "none",
                color: getColor.primary,
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              Login
            </Link>
          </div>
        </div>
      </DialogContent>
      <AlertMessage
        message={message}
        status={status}
        setToast={setToast}
        toast={toast}
      />
    </Dialog>
  );
};

export default connect(null, { checkAuth })(SignUp);
